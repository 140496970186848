:root{
    --primary: #fff
}

.btn-mobile i{
padding-right: 15px;
}

.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline:none;
    border:none;
    cursor: pointer;
    width: auto;
}

.btn--primary{
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline{
   background-color: transparent;
   color: #fff;
   padding: 8px 20px;
   border: 1px solid var(--primary);
   transition: all 0.3s ease-out; 
}

.btn--darkblue{
    background-color: var(--primero-darkblue);
    color: #fff;
    border: 1px solid var(--primero-darkblue);
}

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover{
background-color: #fff;
color:#242424;
transition:  all 0.3s ease-out;
}

@media screen and (max-width:550px){
    .btn{
        font-size: 16px;
    }
}