:root{
    --primero-darkblue: #192e3e;
    --primero-lightblue: #5494b6;
    --primero-orange: #d77336;
    --primero-grey: #C0C0C0;
    }

@font-face {
font-family: 'Titillium-Light';
src: local('TitilliumWeb-ExtraLight'), url(../../Fonts/TitilliumWeb-ExtraLight.woff) format('woff');
}

@font-face {
font-family: 'Titillium-LightItalic';
src: local('TitilliumWeb-ExtraLightItalic'), url(../../Fonts/TitilliumWeb-ExtraLightItalic.woff) format('woff');
}

@font-face {
font-family: 'Titillium-SemiBold';
src: local('TitilliumWeb-SemiBold'), url(../../Fonts/TitilliumWeb-SemiBold.woff) format('woff');
}

@font-face {
    font-family: 'Titillium-Regular';
    src: local('TitilliumWeb-Regular'), url(../../Fonts/TitilliumWeb-Regular.woff) format('woff');
    }

@font-face {
font-family: 'Raleway';
src: local('Raleway'), url(../../Fonts/Raleway-Regular.woff) format('woff');
}

.bg-primero-darkBlue{
    background-color: var(--primero-darkblue);
    color: white;
}

.bg-primero-darkBlue p{
    font-family: 'Titillium-Light' !important;
}


.bg-primero-lightBlue{
    background-color: var(--primero-lightblue);
    color:white;
}

.bg-primero-orange{
    background-color: var(--primero-orange);
    color:var(--primero-darkblue);
}

.bg-primero-white{
    background-color: white;
    color:var(--primero-darkblue);
}

.bg-primero-grey{
    background-color: var(--primero-grey);
    color:var(--primero-darkblue);
}

