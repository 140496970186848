.microsoft-logo{
    height:20px;
    padding: 0 5px 0;
    
}

@media scree and (max-width:960px){
    .microsoft-logo{
        height:18px;
    }
}

@media scree and (max-width:768px){
    .microsoft-logo{
        height:16px;
    }
}