* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    /* background-image: url('/images/img-2.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .products {
    /* background-image: url('/images/img-1.jpg'); */
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    /* background-image: url('/images/img-8.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .fa-linkedin:hover{
    color: #0077B5;
    cursor: pointer;
  }
  
  .fa-github-square:hover{
    color: lightgray;
    cursor: pointer;
  }

  .fa-envelope-square:hover{
    color: var(--primero-orange);
    cursor: pointer;
  }

  .fa-youtube-square:hover{
    color:#ff0000;
    cursor: pointer;
  }