@import './Root.css';

.footer-container{
    background-color: var(--primero-darkblue);
    padding: 4rem 0 2rem 0;
    display: flex;   
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
    padding-top:100px;
    position: relative;
    z-index: 96;
}

.madeby{
    width: 100%;
    text-align: center;
    align-self: flex-end;
}

.contact-title{
    padding-top: 0;
    height:200px;
    text-align: left;
    font-size: 26px;
    color:#fff;
    font-family: 'Raleway';
    background-color: red;
}
