@import './Root.css';

:root{
    --primero-darkblue: #192e3e;
    --primero-lightblue: #5494b6;
    --primero-orange: #d77336;
    }

video{
    object-fit: cover;
    width: 100%;
    height:100%;
    position: fixed;
    z-index: -1;
}

.profile-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:100%;
    padding-top: 40px;
    padding-bottom: 100px;
}

.profile-pic img{
    width:400px;
    border-radius: 50%;
    animation: grow 0.5s ease-out;

}

.profile-description{
    color:white;  
    text-align: left;
    /* padding-right:50px; */
    animation: slowAppear 0.5s linear;
}

.profile-description .title{
    
    font-size:72px;
    font-family: 'Raleway', Arial, Helvetica, sans-serif; 
    animation: slowAppear 0.5s linear;
}

.profile-description .title span{
    color: var(--primero-orange);
}
.profile-description .message > p{
    
    font-size: 36px;
    color:lightgrey;
    font-family: 'Titillium-light', Arial, Helvetica, sans-serif;  
    width: 45vw;
    padding-right: 20px;;
}



.profile-container{
/* background: url('/src/Images/profile5.jpg') center center/cover no-repeat; */
background-color: var(--primero-darkblue);
min-height:90vh;
width:100%;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
object-fit: contain;
-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px)); /*  clip right */
position: relative;
z-index: 99;
padding-bottom: 50px;
/* -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 100px), 0 100%);
clip-path: polygon(0 0, 100% 0, 100% calc(100% - 100px), 0 100%); clip left */

}

.profile-container > h1{
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
} 

.profile-container > p {
 color: #fff;
 margin-top: 8px;
 font-size: 32px;
 font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
 
}

.hero-btns{
    margin-top: 32px;
}

.hero-btn .btn{
    margin: 6px;
}

.fa-play-circle{
    margin-left: 4px;
}


@media screen and (max-width: 1300px){
    .profile-container .title{        
        font-size: 42px;
        margin-top: -30px;
        
    }

    .profile-description .message > p{
        margin-bottom: 10px;
        font-size:30px;
    }

    /* .btn-mobile{
        display: block;
        text-decoration: none;
    } */

    /* .btn{
        width: 100%;
    } */

    .profile-pic img{
        width:300px;
    }
    .profile-section{
        flex-direction: row;
        ;
    }

    .profile-description{
        text-align: left;
        padding-bottom: 30px;
        /* padding-left: 30px; */
    }

    

    
}

@media screen and (max-width: 960px){
    .profile-container > h1{
        
        font-size: 70px;
        margin-top: -50px;
    }

    .profile-pic img{
        width:250px;
    }
    .profile-section{
        flex-direction: column;
        padding-top: 75px;
    }

    .profile-description{
        text-align: center;
        padding-bottom: 30px;
    }

    .profile-description title{
        font-size: 56px;
    }

    .profile-description .message > p{
        font-size:20px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px){
    
    /* .btn-mobile{
        display: block;
        text-decoration: none;
    } */

    /* .btn{
        width: 100%;
    } */

    .profile-pic img{
        width:200px;
    }
    .profile-section{
        padding-top:75px;
        flex-direction: column;
       
        
    }
    .profile-description{
        text-align: center;
        padding-bottom: 30px;
        /* padding-left: 30px; */
    }

    .profile-description .title{
        
        text-align: center;
    }


    .profile-description .message > p{
        margin-bottom: 10px;
        font-size:18px;
        width:80vw;
    }
}


@media screen and (max-width:400px){
    .profile-pic img{        
        width:150px;
    }
   
    .profile-description{
        padding-right: 0;
    }

    .profile-description .title{
        font-size: 30px;
    }
    
}

@keyframes grow{
    0% {transform:  scale(0);}
    85% {transform: scale(1.1);}
    100% {transform: scale(1);}
}

@keyframes slowAppear{
    0% {opacity: 0;}
    100% {opacity: 100;}
}
