
.skill-logo{
    height: 100px;
    padding: 10px 15px 10px;
    transition: all 0.2s linear;
}

.skill-logo:hover{
    transform: scale(1.3);
}

.logo-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;
    padding-top:20px;
    padding-bottom: 50px;
}

@media screen and (max-width:960px){
   .skill-logo {
       height:75px;
   }
}

@media screen and (max-width:400px){
    .skill-logo {
        height:50px;
    }
}