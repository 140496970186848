.body{  
  min-width: 700px;
  width:100%;
  background-color: white;
}

#root{ 
  width:100%;
  background-color: white;
}


/* font software - eras medium */
/* font primero - touka maru gothic */