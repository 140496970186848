@import './Root.css';

/* .cards {
    
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
    margin-top: -100px;
    padding-top: 100px;
    position: relative;
    z-index: 97;    
    padding-bottom: 150px;
  } */
  
  .cards h1 {
    text-align: center;
    font-size: 48px;
    font-family: 'Raleway', Arial, Helvetica, sans-serif; 
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
   
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
    padding-left:0;
    padding-right:0;
  }
  
  .cards__item {
    display: flex;
    flex: 1; 
    margin: 0 1rem;
    border-radius: 10px;
    border: 0px solid var(--primero-lightblue);
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;    
    /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
    /* box-shadow: 0 6px 20px rgba(246, 248, 250, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(184, 181, 181, 0.836)); */
    filter: drop-shadow(0 6px 20px rgba(161, 160, 160, 0.979));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color:var(--primero-orange);
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
    
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 20px;
    background-color: white;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    
  }
  
  .cards__item__title {
    color:var(--primero-darkblue);
    font-size: 26px;
    text-align: center;
    font-family: 'Titillium-light', Arial;
    
  }

  .tech__used{
    
    position: absolute;
    bottom: 0;    
   
    background-color: var(--primero-lightblue);
    width: 100%;
    /* padding-bottom: 20px; */
    color: white;
    font-family: 'Titillium-SemiBold', Arial, Helvetica, sans-serif;
    font-size:16px;
    text-align: center;
  }

  .cards__item__desc{
    color: var(--primero-darkblue);
    font-size:20px;
    text-align: center;
    font-family: 'Titillium-light', Arial, Helvetica, sans-serif;
    padding-bottom:60px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 960px){
    
  }

  @media screen and (max-width: 768px){
    .cards__item__title{
      font-size: 22px;
    }

    .cards__item__desc{
      font-size: 18px;
    }

    .tech__used{
      font-size: 16px;
    }
  }

  @media screen and (max-width: 400px){
    .cards__item__title{
      font-size: 18px;
    }

    .cards__item__desc{
      font-size: 16px;
    }

    .tech__used{
      font-size: 14px;
    }
  }