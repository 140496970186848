.page-heading{
    background-color: var(--primero-darkblue);
    min-height:500px;
    height:auto;
    color:white;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding-bottom:100px;
    position: relative;
    z-index: 99;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
    margin-top: -100px;
    padding-top: 100px;
    
}
.button-container{
    padding-top:30px;
}

.button-container button{
    margin-left:15px;
    margin-right: 15px;
}
.heading-title{
    font-size: 42px;    
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
    text-align: center;
    padding-bottom: 20px;
}

.heading-text{
    font-size: 22px;
    font-family: 'Titillium-Light', Arial, Helvetica, sans-serif;
    text-align: center;
    width: 90vw;
}

.page-body-section{
    /* min-height: 300px; */
    height: auto;    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top:10px;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 100px;
    margin-top: -1px;
}

.second-to-last-section{    
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
    position: relative;
    z-index: 98;
    padding-bottom: 150px;
}

.shift-up-for-line{
    margin-top: -100px;
    padding-top: 150px;
    padding-bottom:100px;
    position: relative;
    z-index: 97;    
}


.section-divide{
    width:90%;
    height: 5px;
    border: 1;
    box-shadow: inset 0 9px 9px -3px rgba(165, 165, 165, 0.5);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

.blank-section{
    height:101px;
    min-height:100px;
    padding-top:0;
    padding-bottom:0;
   
}

.ul-in-paragraph{
    margin-top: -10px;
    padding:0 30px 20px;
}
.page-body-section ul{    
    font-size: 20px;
}

.page-body-section ul li{
    font-family: 'Titillium-Regular', Arial, Helvetica, sans-serif;
    font-size:20px;
}


/* .section-heading{
    font-size: 48px;    
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
    padding-bottom: 30px;
} */


.with-image{
    flex-direction: row ;    
}

.image-left{
    flex-direction: row;
    
}

.image-right{
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.section-image{
    /* padding: 0 40px 0;     */
    transition: all 0.3s linear;
}

/* .section-image:hover{
    cursor: zoom-in;
} */

.image-rounded{
    border-radius: 50px;
}

.image-normal{
    width:500px;
}
.image-large{
    width: 700px;    
}

.image-shadow{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.section-image-caption{
    text-align: center;
    font-style: italic;
    font-size: 14px;    
}

.image-expandable{    
    object-fit: cover;
    object-position: top;
    height: 400px;
    animation: scrollImage 10s linear alternate infinite;
}

.image-expandable:hover{    
    height: auto;
}

.section-image-caption figcaption{
    padding-top:10px;
}

.section-text-container{
    padding: 0 20px 0;
}

.section-title{
    font-size: 48px;    
    font-weight: bold;
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
    padding-bottom: 20px;    
}

.section-text{
    width:100%;
}

.section-text p{
    font-size: 28px;
    font-family: 'Titillium-Regular', Arial, Helvetica, sans-serif;
    padding-bottom:30px;    
    
}

.p-title{
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
}


.demo-video{
    display:flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.demo-video iframe{
    margin-top:30px;    
}

.text-center{
    text-align: center;
}

.full-page-image{
    /* position: absolute;
    top: 100px;
    bottom: 0px;
    left: 15%;
    right: 0;
    height:70%;
    width:auto; */
    transform: scale(1.6);
}

@media screen and (max-width:1900px){
    .section-title{
        font-size:30px;
    }
    .section-text p{
        font-size: 20px;
    }
}
@media screen and (max-width:1349px){    
    
    .image-large{
        width: 400px;
    }

   
    .image-expandable{
        width:400px
    }

    .section-title{
        font-size: 30px;    
    }

    .section-text p{ 
        font-size: 20px;
    }

    .page-body-section ul li{    
        font-size:20px;
    }
}

@media screen and (max-width:1150px){    
    
    .image-large{
        width: 300px;
    }
    .image-normal{
        width:400px;
    }

    .image-expandable{
        width:300px;
    }
}

@media screen and (max-width:960px){

    .page-body-section{
        padding-left:15px;
        padding-right:15px;    
    }

    .with-image{
        flex-direction: column;       
    }

    .with-image .section-title{
        text-align: center;
        padding-top: 30px;   
    }

    .with-image .section-text-container{
        width:100%;
    }

    .with-image .section-image-caption{
    order: 2;
    -webkit-order: 2;
    padding-top: 30px;
    }

    .image-normal{
        width: 70%;
    }

    .image-large{
        width: 100%;
    }

    .with-image .section-image-caption .image-large img{
        height:300px;
    }

    .with-image .section-image-caption figcaption {
        font-size:12px;
    }

    .section-text p{
        font-size:18px;
        padding: 20px 30px 0;
    }

    .section-heading{
        font-size: 30px;    
        font-family: 'Raleway', Arial, Helvetica, sans-serif;
    }

    .section-text p{
        font-size: 18px;    
    }

    .page-body-section ul li{    
        font-size:18px;
    }

    .heading-title{
        font-size: 36px;      
    }

    .heading-text{
        font-size: 22px;    
    }

    .demo-video iframe{    
        width:650px;
        height: 315px;
        }
    }

    @media screen and (max-width:768px){
            
        .demo-video iframe{        
            width:395px;
            height: 210px;
            }
        .heading-title{
            font-size: 30px;      
        }
        
        .heading-text{
            font-size: 18px;    
        }

        .section-text p{
            padding-left: 2px;
            padding-right: 2px;
            font-size: 16px;
        }

        .ul-in-paragraph{
            padding-left:40px;
            padding-right:30px;
        }

    .page-heading{
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 50px));
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 50px));
    }

    .second-to-last-section{    
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 50px));
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 50px));
    }
    .page-body-section{
        /* padding-left:50px;
        padding-right:50px;     */
    }
        
    .page-body-section ul li{    
        font-size:16px;
    }
}

    @media screen and (max-width:400px){
        
        .demo-video iframe{        
            width:320px;
            height: 160px;
            }       
        
            .image-large{
                width: 350px;
            }

            .heading-title{
                font-size: 22px;      
            }
            
            .heading-text{
                font-size: 14px;    
            }

            .button-container{
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 0;
                padding-right: 0;
            }

            .button-container button{
               
                margin-bottom: 15px;
            }
        /* .section-image{
            height: 900px;
            width: 30px;
        } */
    }

    @keyframes scrollImage{
        0% {object-position: top ;}
        40% {object-position: bottom;}
        50% {object-position: bottom;}
        90% {object-position: top ;}
        100% {object-position: top ;}
    }