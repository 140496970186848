.contact-section{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;   
    width: 100%;    
}

.contact-details{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactText{
    font-family: 'Raleway';
    font-size: 22px;
    padding-bottom: 20px;
    text-align: center;
}

.form-section{
    padding-top: 50px;
    width:100%;

}

.email{
    font-size: 28px;
    font-family: 'Titillium-Light';
}

.email a{
    color: inherit;
    text-decoration: none;
    font-family: inherit;
    
}

.social-icons{
    font-size:64px;
}

.social-icons a{
    color:inherit;
}

.social-icons i{
    padding-right: 10px;
}

.email a:hover{
    cursor: pointer;
    font-weight: bold;
    
}

@media screen and (max-width:768px){
    .contactText{
        font-size: 18px;
    }

    .social-icons{
        font-size: 28px;
    }
}

@media screen and (max-width:400px){
    .contactText{
        font-size: 14px;
    }

    .social-icons{
        font-size: 28px;
    }
}